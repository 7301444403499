.App {
  text-align: center;
}

.app {
    min-height: 100vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    /*padding-bottom: 630px; !* height of your footer *!*/
    /*background-color: azure;*/
}

.custom-jumbotron {
    text-align: center;
    padding: 15px;
}


.call-button {
    border: 2px solid #2196F3;
    border-radius: 32px;
    font-size: 16px;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    color: white;
    background: none;
}

.call-button:hover {
    background: white;
    color: #2196F3;
}

.call-small {
    border-radius: 16px;
    font-size: 16px;
    padding: 10px;
    margin: 0;
    cursor: pointer;
    color: white;
    background: none;
}

.call-small:hover {
    background: white;
    color: #2196F3;
}

.logo {
    font-size: 25px;
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 5px;
}

.footer-bottom {
    color: white;
}

@media only screen and (max-width: 849px) {
    .abq {
        width: 100%;
        height: 50vh;
        margin-top: 60px;
    }
    .navbar {
        background-color: #1C2331;
    }

    .home-header-1, .custom-jumbotron .home-intro-1, .custom-jumbotron .insurance-header,
    .custom-jumbotron .faq-header, .about-dentist-header, .services-header, .forms-header{
        font-size: 35px;
    }

    .home-header-2, .custom-jumbotron .home-intro-2, .custom-jumbotron p, .about-text p,
    .forms-card-header, .forms-text-1, .forms-text-2{
        font-size: 20px;
    }


    .call {
        display: none;
    }
}

@media only screen and (min-width: 850px) {
    .abq {
        width: 100%;
        height: 100vh;
    }
    .call-small{
        display: none;
    }
    .home-header-1, .custom-jumbotron .home-intro-1, .custom-jumbotron .insurance-header,
    .custom-jumbotron .faq-header, .about-dentist-header, .services-header, .forms-header{
        font-size: 45px;
    }

    .home-header-2, .custom-jumbotron .home-intro-2, .custom-jumbotron p, .about-text p,
    .forms-card-header, .forms-text-1, .forms-text-2{
        font-size: 25px;
    }
}

/**********************
  Home Page
 **********************/

.accordion {
    background-color: white;
    cursor: pointer;
    padding: 13px;
    width: 100%;
    border: 1px solid #EEEEEE;
    border-radius: 6px;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: .3s;
}

.accordion:hover {
    background-color: #EEEEEE;
}

.panel {
    padding: 0 18px;
    background-color: white;
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s linear;
}

.home .footer {
    /*min-height: 100vh; !* will cover the 100% of viewport *!*/
    /*overflow: hidden;*/
    display: block;
    position: relative;
    margin-top: 630px; /* height of your footer */
    background-color: azure;
    bottom: 0;
    width: 100%;
}

/*.testimonials {*/
    /*display: block;*/
    /*text-align: center;*/
    /*padding-top: 650px;*/

/*}*/

/**********************
  About Page
 **********************/
.about-dentist p {
    display: block;
    text-align: center;
    padding-top: 80px;
    padding-bottom: 15px;
}

.about-dentist img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

 .about-text{
    padding: 40px;
}

 .about-text p {
     padding-bottom: 10px;
     font-size: 130%;
     color: black;
 }

/**********************
 Services Page
**********************/
.services {
    display: block;
}
.services .services-header {
    display: block;
    text-align: center;
    padding-top: 100px;
}

.services .service {
    text-align: center;
    margin: 25px;
}

.dental-icon {
    display: block;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    width: 25%;
}

#icons-credit {
    text-align: center;
    margin-top: 10px;
    font-size: small;
}

.services .footer {
    /*min-height: 100vh; !* will cover the 100% of viewport *!*/
    /*overflow: hidden;*/
    display: block;
    position: relative;
    margin-top: 9%; /* height of your footer */
    /*background-color: azure;*/
    /*bottom: 0;*/
    /*width: 100%;*/
}

/**********************
 Contact Page
**********************/

.contact h1 {
    display: block;
    text-align: center;
    padding-top: 100px;
}

.contact .footer {
    /*min-height: 100vh; !* will cover the 100% of viewport *!*/
    overflow: hidden;
    display: block;
    position: relative;
    margin-top: 630px; /* height of your footer */
    /*background-color: azure;*/
    /*bottom: 0;*/
    /*width: 100%;*/
}

/**********************
 Forms Page
**********************/

.forms .forms-header {
    display: block;
    text-align: center;
    padding-top: 100px;
}

.forms .forms-jumbotron {
    display: flex;
    width: 100%;
    padding: 0;
    margin-top: 10px;
    justify-content: center;
}



.forms .footer {
    /*min-height: 100vh; !* will cover the 100% of viewport *!*/
    overflow: hidden;
    display: block;
    position: relative;
    margin-top: 18%; /* height of your footer */
    /*background-color: azure;*/
    /*bottom: 0;*/
    /*width: 1 00%;*/
}
